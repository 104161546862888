.selectionListTips[data-v-64f03283] {
  padding: 0 0 10px 12px;
}
.visibility[data-v-64f03283] {
  visibility: hidden;
}
[data-v-64f03283] .avue-crud .el-table {
  height: calc(100vh - 538px) !important;
  max-height: calc(100vh - 538px) !important;
}
[data-v-64f03283] .el-checkbox {
  margin-right: 5px !important;
}
.paperDialog[data-v-64f03283] .el-dialog__body {
  padding: 10px;
}
.knowledge[data-v-64f03283] .el-dialog__body {
  padding-top: 20px !important;
}
.paperDialog[data-v-64f03283] .el-tree {
  height: calc(100vh - 348px) !important;
}
.paperDialog[data-v-64f03283] .el-table {
  height: calc(100vh - 400px) !important;
  max-height: calc(100vh - 400px) !important;
}
.bottomPagination[data-v-64f03283] {
  background-color: #FFFFFF;
  padding: 0 12px 10px 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.questionStaics[data-v-64f03283] {
  padding: 10px 0 10px 4px;
  background-color: #FFFFFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.questionStaics .q-item[data-v-64f03283] {
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}